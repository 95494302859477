ul li {
  list-style: none;
}

/*iframes*/

.iframe, .exploreIframe{
    width: 100%;
    height: 100%; 
    min-height: 800px;
    /* height: var(--element-height); */
}
.embedContainer iframe.splashPage {
  height: 100%;
  width: 100%;
  /* margin-top: -35px; */
}

/* override */
.embedContainer iframe.dNone {
  display: none;
}
.embedContainer iframe.dBlock {
  display: block;
}

.embedContainer iframe {
  width: 100%;
  height: 95%;
}

.embedContainer .iframe-dashboard {
  width: 95%;
  min-height: 800px;
  /* height: 95%; */
}

/* hide scrollbars */
html {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
}
::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
  /* background: #FF0000; */
}

.svg-map {
  width: 200px;
  display: block;
  height: auto;
  margin: 0 auto;
  stroke: #666;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round; }
  .svg-map__location {
    fill: #e5e5e5;
    cursor: pointer; }
    .svg-map__location:focus, .svg-map__location:hover {
      fill: #e5e5e5;
      outline: 0; }
    .svg-map__location[aria-checked=true] {
      fill: #343D4E; }

html, body, #root, #root>div {
  height: 100%
}

body {
  background-color: #ffffff;
}
